<template>
  <v-text-field
    :value="colsString"
    dense
    outlined
    :rules="validations.responsive"
    @input="updateCol({ val: $event, colIndex, rowIndex, field: 'colsString' })"
    label="Responsiivisuus"
    hide-details
  >
    <template slot="append-outer">
      <v-icon
        style="cursor: pointer; margin-top: 5px"
        size="15"
        color="error"
        @click="
          removeUnnecessaryRules({ rowIndex, colIndex });
          deleteCol({ rowIndex, colIndex });
        "
        >mdi-delete</v-icon
      >
    </template></v-text-field
  >
</template>

<script>
import { mapMutations } from "vuex";
import validations from "@/validations";

export default {
  props: ["colIndex", "rowIndex"],

  data() {
    return {
      validations,
    };
  },

  computed: {
    colsString() {
      return this.$store.state.form.currentForm.rows[this.rowIndex].cols[this.colIndex].colsString;
    },

    colsStringRules() {
      return [(v) => this.validateResponsivenes(v) || true];
    },
  },

  methods: {
    ...mapMutations("form", ["updateCol", "deleteCol", "removeUnnecessaryRules"]),
  },
};
</script>
